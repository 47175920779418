import React from 'react';
import { SettingAccountAvatar } from './components/SettingAccountAvatar';
//import { useStore } from '@cobuildlab/react-simple-state';
//import { updateProfileStore } from './setting-events';
//import { SettingAccount } from './components/SettingAccount';
import { CardSection } from '../../shared/components/card/CardSection';
import { Box /**, Theme */, Typography } from '@mui/material';
import { SettingAccount } from './components/SettingAccount';
import { updateProfileStoreAction } from './setting-actions';
//import { updateProfileStoreAction } from './setting-actions';
//import { CloseOutlined, EditOutlined } from '@mui/icons-material';
//import { createStyles, makeStyles } from '@mui/styles';

/**const useStyles = makeStyles<Theme>(() =>
  createStyles({
    edit: {
      cursor: 'pointer',
    },
  }),
); */
export const SettingAccountViewProvider: React.FC = () => {
  //const { isChanged } = useStore(updateProfileStore);
  //const classes = useStyles();
  updateProfileStoreAction(false);
  return (
    <>
      <SettingAccountAvatar />
      <CardSection
        title={
          <Typography variant="h6" fontWeight={800} textTransform="uppercase">
            Vincular red social
          </Typography>
        }
        /**actions={
          <Box
            mt={1}
            onClick={() => updateProfileStoreAction(!isChanged)}
            className={classes.edit}
          >
            {!isChanged ? <EditOutlined /> : <CloseOutlined />}
          </Box>
        } */
      >
        <Box p={2}>
          <SettingAccount />
        </Box>
      </CardSection>
    </>
  );
};
