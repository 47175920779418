import React, { useCallback, useState } from 'react';
import { Box, Grid, TextField } from '@mui/material';
import { EmailOutlined } from '@mui/icons-material';
import { styled } from '@mui/styles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ButtonDefault } from '../../../shared/components/ButtonDefault';
import { authStore } from '../auth-events';
import { useStore } from '@cobuildlab/react-simple-state';
import { authStepAction, authStoreAction } from '../auth-actions';
import { AuthStep } from '../auth-types';
import {
  useLoginMutation,
  useRecaptchaMutation,
} from '../../../shared/types/generated';
import { COOKIES_OPTIONS, USER_COOKIE } from '../../../shared/constans';
import { redirectToHome } from '../../../shared/utils';
import { useCookies } from 'react-cookie';
import { openDialogAlert } from '../../alert/alert-actions';
import { Key } from '@mui/icons-material';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import validator from 'validator';
import { createProvider } from '../auth-utils';

const StyledButton = styled(ButtonDefault)({
  borderRadius: '6px!important',
  backgroundColor: '#FF393F!important',
  border: 'none!important',
  color: 'white!important',
  '&:disabled': {
    backgroundColor: '#FF9EA1!important',
  },
  width: 200,
});

const StyledIcon = styled(EmailOutlined)({
  color: '#999999',
  fontSize: '20px!important',
  paddingRight: '5px',
});
const StyledIconKey = styled(Key)({
  color: '#999999',
  fontSize: '20px!important',
  paddingRight: '5px',
});

const StyledVisibilityIcon = styled(VisibilityIcon)({
  cursor: 'pointer',
  color: '#999999',
  fontSize: '20px!important',
});

const StyledBox = styled(Box)({
  cursor: 'pointer',
});

export const AuthLoginForm: React.FC = () => {
  const { email, isLogin } = useStore(authStore);
  const [loading, setLoading] = useState<boolean>(false);
  const [passwordType, setPasswordType] = useState<string | undefined>(
    undefined,
  );
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [, setCookie] = useCookies([USER_COOKIE, 'analytics_uuid']);
  const [password, setPassword] = useState('');
  const handleError = useCallback(() => {
    setLoading(false);
    openDialogAlert('Tenemos problemas para verificar el usuario');
  }, []);

  const [authLogin] = useLoginMutation({
    onCompleted: ({ login }) => {
      setCookie(USER_COOKIE, login?.token, COOKIES_OPTIONS);
      createProvider('Correo electrónico');

      setLoading(false);
      redirectToHome('/');
    },
    onError: (error) => {
      openDialogAlert(error.message);
      setLoading(false);
    },
  });

  const [authRecaptcha] = useRecaptchaMutation({
    onCompleted: () => {
      authLogin({
        variables: {
          data: {
            email,
            password,
          },
        },
      });
    },
    onError: () => handleError(),
  });

  const checkUser = useCallback(async () => {
    if (!executeRecaptcha) {
      return handleError();
    }
    const token = await executeRecaptcha('auth_email');

    authRecaptcha({
      variables: {
        token,
        email: email,
      },
    });
  }, [email, executeRecaptcha, authRecaptcha, handleError]);

  const handleVisibility = (): void => {
    if (!passwordType) setPasswordType('text');
    else setPasswordType(undefined);
  };

  const onSubmit = (): void => {
    setLoading(true);
    checkUser();
  };

  return (
    <Grid container rowSpacing={2}>
      <Grid marginTop={3} item xs={12}>
        <TextField
          variant="outlined"
          placeholder={'Direccion de correo electrónico'}
          value={email}
          name="email"
          type="text"
          fullWidth
          InputProps={{
            startAdornment: <StyledIcon />,
            style: { background: 'white', fontSize: '18px', height: '48px' },
          }}
          InputLabelProps={{
            style: { fontSize: '18px' },
          }}
          onChange={(event) => authStoreAction(event.target.value, isLogin)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          placeholder={'Contraseña'}
          name="password"
          type={passwordType ?? 'password'}
          value={password}
          fullWidth
          variant="outlined"
          color="primary"
          FormHelperTextProps={{ style: { fontSize: '16px' } }}
          InputLabelProps={{
            style: { fontSize: '18px' },
          }}
          InputProps={{
            endAdornment: (
              <StyledVisibilityIcon onClick={() => handleVisibility()} />
            ),
            startAdornment: <StyledIconKey />,
            style: { fontSize: '18px', background: 'white', height: '48px' },
          }}
          onChange={(event) => {
            setPassword(event.target.value);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Box textAlign="center">
          <StyledButton
            isLoading={loading}
            variant="contained"
            size="large"
            color="secondary"
            fullWidth
            disabled={!validator.isEmail(email as string) || password === ''}
            onClick={() => onSubmit()}>
            Continuar
          </StyledButton>
        </Box>
      </Grid>
      <Grid item xs={12} mb={1}>
        <StyledBox
          color="#FE0000"
          textAlign="center"
          fontSize={18}
          onClick={() => authStepAction(AuthStep.authForgotPassword)}>
          ¿Olvidó su contraseña?
        </StyledBox>
      </Grid>
    </Grid>
  );
};
