import React, { useEffect } from 'react';
import { MainLoader } from '../../shared/components/MainLoader';
import { useCookies } from 'react-cookie';
import { DOMAIN_SITE, USER_COOKIE } from '../../shared/constans';
import { redirectToHome } from '../../shared/utils';

export const AuthLogout: React.FC = () => {
  const [cookies, , removeCookie] = useCookies([USER_COOKIE]);
  console.log('cookies', cookies);
  useEffect(() => {
    if (cookies._u) {
      removeCookie(USER_COOKIE, { path: '/', domain: DOMAIN_SITE });
      redirectToHome('/autenticacion');
    } else {
      redirectToHome('/autenticacion');
    }
  }, [cookies._u, removeCookie]);

  return <MainLoader />;
};
