import React, { useState, ChangeEvent } from 'react';
import { Grid, TextField, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { Person, Key, CalendarMonth, CreditCard } from '@mui/icons-material';
import InputMask from 'react-input-mask';
import { ButtonDefault } from '../../shared/components/ButtonDefault';
import { useStore } from '@cobuildlab/react-simple-state';
import { changePlanStore } from '../payment/payment-events';
import { checkCreditCard, translate } from './open-pay-utils';
import { openDialogAlert } from '../alert/alert-actions';
import cardValidator from 'card-validator';
import { createOpenPaySubscription } from './open-pay-actions';
import {
  useCreateOpenPayChargeMutation,
  useCreateOpenPaySubscriptionMutation,
} from '../../shared/types/generated';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    icon: {
      color: '#999999',
      fontSize: '20px!important',
      paddingRight: '5px',
    },
    input: {
      background: 'white',
      fontSize: '18px',
      height: '48px',
    },
    button: {
      borderRadius: '5px!important',
      backgroundColor: '#FE0000!important',
      border: 'none!important',
      color: 'white!important',
      '&:disabled': {
        backgroundColor: '#FFCCCC!important',
      },
      height: 45,
    },
  }),
);

type CreditCardType = {
  cardHolderName: string;
  expiredDate: string;
  cvc: string;
  creditCardNumber: string;
};
export const OpenPay: React.FC = () => {
  const classes = useStyles();
  const [creditCard, setCreditCard] = useState<CreditCardType>({
    cardHolderName: '',
    expiredDate: '',
    cvc: '',
    creditCardNumber: '',
  });
  const { plan } = useStore(changePlanStore);
  const [loading, setLoading] = useState(false);

  const [callSubscriptionAction] = useCreateOpenPaySubscriptionMutation({
    onCompleted: () => {
      //setLoading(false);
      // TODO: CAMBIAR URL DE VERIFICACIÓN
      window.location.href = 'https://www.noroeste.com.mx/premium-completado';
    },
    onError: (data) => {
      setLoading(false);
      openDialogAlert(translate(data.message));
    },
  });

  const [callChargeAction] = useCreateOpenPayChargeMutation({
    onCompleted: (data) => {
      //setLoading(false);
      // TODO: CAMBIAR URL DE VERIFICACIÓN
      window.location.href = data.createOpenPayCharge?.redirect_url as string;
    },
    onError: (data) => {
      setLoading(false);
      openDialogAlert(translate(data.message));
    },
  });

  const onChangeHandler = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ): void => {
    setCreditCard({
      ...creditCard,
      [event.target.name as keyof CreditCardType]: event.target.value as string,
    });
  };

  const onHandlePayment = async (): Promise<void> => {
    const checked = checkCreditCard(creditCard);

    if (checked) {
      return openDialogAlert(checked);
    }

    const date = cardValidator.expirationDate(creditCard.expiredDate);

    const sanitizeCreditCard = {
      card_number: (creditCard.creditCardNumber as string).replaceAll(' ', ''),
      holder_name: creditCard.cardHolderName as string,
      cvv2: creditCard.cvc as string,
      expiration_month: date.month as string,
      expiration_year: date.year as string,
    };

    setLoading(true);

    await createOpenPaySubscription(
      sanitizeCreditCard,
      (data) => {
        const obj = {
          ...data,
          planId: plan?.id,
        };

        return !plan?.open_pay?.first_payment_price
          ? callSubscriptionAction({
              variables: {
                data: obj,
              },
            })
          : callChargeAction({
              variables: {
                data: obj,
              },
            });
      },
      () => {
        openDialogAlert('Tenemos problemas para crear la suscripción');
        setLoading(false);
      },
    );
  };

  if (!plan?.open_pay)
    return (
      <Typography variant="subtitle1" fontWeight={800} textAlign="center">
        Open Pay no esta configurado para este plan, contacte a su administrador
      </Typography>
    );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          placeholder={'Nombre del titular'}
          name="cardHolderName"
          fullWidth
          InputLabelProps={{
            shrink: true,
            style: { fontSize: 19 },
          }}
          variant="outlined"
          InputProps={{
            startAdornment: <Person className={classes.icon} />,
            className: classes.input,
          }}
          onChange={(event) => onChangeHandler(event)}
        />
      </Grid>
      <Grid item xs={12}>
        <InputMask
          mask="9999 9999 9999 9999"
          maskChar=" "
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          maskPlaceholder={null}
          onChange={(event) => onChangeHandler(event)}
        >
          <TextField
            placeholder={'Número de tarjeta'}
            name="creditCardNumber"
            fullWidth
            variant="outlined"
            InputProps={{
              startAdornment: <CreditCard className={classes.icon} />,
              className: classes.input,
            }}
            InputLabelProps={{
              shrink: true,
              style: { fontSize: 19 },
            }}
          />
        </InputMask>
      </Grid>

      <Grid item xs={12}>
        <InputMask
          mask="99/99"
          maskChar=" "
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          maskPlaceholder={null}
          onChange={(event) => onChangeHandler(event)}
        >
          <TextField
            placeholder={'Fecha de vencimiento'}
            name="expiredDate"
            fullWidth
            variant="outlined"
            InputProps={{
              startAdornment: <CalendarMonth className={classes.icon} />,
              className: classes.input,
            }}
            InputLabelProps={{
              shrink: true,
              style: { fontSize: 16 },
            }}
          />
        </InputMask>
      </Grid>
      <Grid item xs={12}>
        <InputMask
          mask="9999"
          maskChar=" "
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          maskPlaceholder={null}
          onChange={(event) => onChangeHandler(event)}
        >
          <TextField
            placeholder={'CVV/CVC'}
            name="cvc"
            fullWidth
            variant="outlined"
            InputProps={{
              startAdornment: <Key className={classes.icon} />,
              className: classes.input,
            }}
            InputLabelProps={{
              shrink: true,
              style: { fontSize: 16 },
            }}
          />
        </InputMask>
      </Grid>
      <Grid item xs={12}>
        <ButtonDefault
          variant="contained"
          size="large"
          fullWidth
          isLoading={loading}
          onClick={() => onHandlePayment()}
          className={classes.button}
        >
          Pagar
        </ButtonDefault>
      </Grid>
    </Grid>
  );
};
