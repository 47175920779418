import React from 'react';
import { Box, Grid, TextField, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import { ButtonDefault } from '../../../shared/components/ButtonDefault';
import { authStore } from '../auth-events';
import { useStore } from '@cobuildlab/react-simple-state';
import { getCurrentUrl, getFormType } from '../auth-utils';
import { authStepAction, authStoreAction } from '../auth-actions';
import { AuthStep } from '../auth-types';
import { useForgotPasswordMutation } from '../../../shared/types/generated';
import { openDialogAlert } from '../../alert/alert-actions';
import { PRIMARY_COLOR } from '../../../shared/css/theme';
import { EmailOutlined } from '@mui/icons-material';
import validator from 'validator';

const StyledIcon = styled(EmailOutlined)({
  color: '#999999',
  fontSize: '20px!important',
  paddingRight: '5px',
});

const StyledTypographyText = styled(Typography)({
  color: '#999999',
  fontSize: 16,
});

const StyledButton = styled(ButtonDefault)({
  borderRadius: '6px!important',
  backgroundColor: `${PRIMARY_COLOR}!important`,
  border: 'none!important',
  color: 'white!important',
  '&:disabled': {
    backgroundColor: '#FF9EA1!important',
  },
  width: 200,
});

export const AuthForgotPassword: React.FC = () => {
  const form = getFormType() as string;
  const redirectUrl = getCurrentUrl() as string;
  const { email, isLogin } = useStore(authStore);

  const [userForgotPassword, { loading }] = useForgotPasswordMutation({
    onCompleted: () => {
      //authStepAction(AuthStep.authLogin);
      openDialogAlert(
        'Se ha enviado un correo exitosamente a tu dirección, no olvides revisar la sección de spam',
        () => authStepAction(AuthStep.authLogin),
      );
    },
    onError: () =>
      openDialogAlert('Tenemos problemas para restaurar su contraseña'),
  });

  return (
    <Box>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Box mb={2}>
            <Typography fontSize={'24px'}>Restaurar contraseña</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} mb={2} style={{ textAlign: 'center' }}>
          <TextField
            variant="outlined"
            value={email}
            name="email"
            placeholder={'Direccion de correo electrónico'}
            type="text"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              startAdornment: <StyledIcon />,
              style: { fontSize: '18px', background: 'white', height: '48px' },
            }}
            onChange={(event) => authStoreAction(event.target.value, isLogin)}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <StyledTypographyText>
            {`Enviaremos un mensaje al correo ${email} para restablecer
              la contraseña`}
          </StyledTypographyText>
        </Grid>
        <Grid item xs={12}>
          <Box textAlign="center" my={2}>
            <StyledButton
              isLoading={Boolean(loading)}
              variant="contained"
              size="large"
              color="secondary"
              disabled={!validator.isEmail(email as string)}
              fullWidth
              onClick={() => {
                userForgotPassword({
                  variables: {
                    email,
                    form,
                    redirectUrl,
                  },
                });
              }}
            >
              Aceptar
            </StyledButton>
          </Box>
          <Box
            color="#FE0000"
            textAlign="center"
            fontSize={18}
            onClick={() => authStepAction(AuthStep.authLogin)}
            component={'div'}
            style={{ cursor: 'pointer' }}
          >
            {'< Volver'}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
