export const SETTING_ROUTES = [
  {
    path: '/',
    name: 'Perfil',
  },
  {
    path: '/vincular',
    name: 'Vincular',
  },
  {
    path: '/transacciones',
    name: 'Transacciones',
  },
  {
    path: '/biblioteca',
    name: 'Biblioteca',
  },
  {
    path: '/detalles-de-facturacion',
    name: 'Detalles de facturacion',
  },
  {
    path: '/notificaciones',
    name: 'Notificaciones',
  },
];
