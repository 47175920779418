import { CreditCard } from './open-pay-types';
import cardValidator from 'card-validator';

export const checkCreditCard = (creditCard: CreditCard): string | null => {
  const checkCreditCardField = Object.keys(creditCard).find(
    (_creditCard) => creditCard[_creditCard as keyof CreditCard] === '',
  );

  if (checkCreditCardField) {
    return 'Todos los campos de la tarjeta son requeridos';
  }

  if (!cardValidator.number(creditCard.creditCardNumber).isValid) {
    return 'El numero de tarjeta es incorrecto';
  }

  if (!cardValidator.cvv(creditCard.cvc).isValid) {
    return 'El CVV es incorrecto';
  }

  if (!cardValidator.expirationDate(creditCard.expiredDate).isValid) {
    return 'La fecha de expiración es incorrecta';
  }

  return null;
};

export const translate = (e: string): string => {
  console.log({ e });
  let msg = 'Hubo un error al procesar el pago';
  if (isNaN(parseInt(e))) {
    return e;
  }

  switch (e) {
    /* ERRORES GENERALES */
    case '1000':
    case '1004':
    case '1005':
      msg = 'Servicio no disponible.';
      break;
    /* ERRORES TARJETA */
    case '3001':
    case '3004':
    case '3005':
    case '3007':
      msg = 'La tarjeta fue rechazada.';
      break;
    case '3002':
      msg = 'La tarjeta ha expirado.';
      break;
    case '3003':
      msg = 'La tarjeta no tiene fondos suficientes.';
      break;
    case '3006':
      msg =
        'La operación no esta permitida para este cliente o esta transacción.';
      break;
    case '3008':
      msg = 'La tarjeta no es soportada en transacciones en línea.';
      break;
    case '3009':
      msg = 'La tarjeta fue reportada como perdida.';
      break;
    case '3010':
      msg = 'El banco ha restringido la tarjeta.';
      break;
    case '3011':
      msg =
        'El banco ha solicitado que la tarjeta sea retenida. Contacte al banco.';
      break;
    case '3012':
      msg =
        'Se requiere solicitar al banco autorización para realizar este pago.';
      break;
    case '2007':
      msg =
        'El número de la tarjeta utilizada es valida solo en modo de prueba.';
      break;
    default: /* Demás errores 400 */
      msg = 'La petición no pudo ser procesada.';
      break;
  }
  return msg;
};
