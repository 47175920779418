import { User, UserRoles } from '../../shared/types/generated';
import { RBACSchema } from './rbac-permissions';
import { RBACPermissions } from './rbac-types';
import { GUEST_ROLE } from '../../shared/constans';

export const getHighestRole = (user: User | undefined | null): string => {
  const totalRoles = user?.roles_on_users?.length ?? 0;
  for (let i = 0; i < totalRoles; i++) {
    if (user?.roles_on_users?.[i]?.role?.name === UserRoles.Administrator) {
      return UserRoles.Administrator;
    } else if (user?.roles_on_users?.[i]?.role?.name === UserRoles.Suscriptor) {
      return UserRoles.Suscriptor;
    } else if (user?.roles_on_users?.[i]?.role?.name === UserRoles.User) {
      return UserRoles.User;
    }
  }
  return GUEST_ROLE;
};
export const checkUserRole = (
  user: User | undefined | null,
  permission: RBACPermissions,
): boolean => {
  const roleName = getHighestRole(user);
  return !!RBACSchema.find(
    (schema) =>
      schema.Role === roleName && schema.Permissions.includes(permission),
  );
};
