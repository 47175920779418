import React, { useEffect, useState } from 'react';
//import { useAuth } from '../auth/auth-hook';
import { Typography } from '@mui/material';
import { CardSection } from '../../shared/components/card/CardSection';
import { TableDefault } from '../../shared/components/table/TableDefault';
import { TableColumnType } from '../../shared/components/table/table-types';
import {
  Invoice,
  useGetInvoiceByUserLazyQuery,
} from '../../shared/types/generated';
import moment from 'moment';
import { getStatusText } from './invoice-utils';

export const Invoices: React.FC = () => {
  //const { user } = useAuth();
  const [pagination, setPagination] = useState({
    pageSize: 10,
    page: 1,
  });
  const [invoices, setInvoices] = useState<Invoice[] | null | undefined>();
  const [total, setTotal] = useState(0);
  const [callUserInvoices, { loading }] = useGetInvoiceByUserLazyQuery({
    onCompleted: (data) => {
      setInvoices(data?.getInvoicesByUserId?.invoices as Invoice[]);
      setTotal(data.getInvoicesByUserId?.total ?? 0);
    },
  });
  //const invoices = user?.invoices || [];
  const COLUMNS: TableColumnType<Invoice>[] = [
    {
      columnName: 'Fecha de compra',
      columnValue: (item: Invoice) =>
        moment(parseInt(item?.created_at as string)).format('DD-MM-YYYY'),
    },
    {
      columnName: 'Recurso',
      columnValue: (item: Invoice) => item?.plan?.title as string,
    },
    {
      columnName: 'Estatus',
      columnValue: (item: Invoice) => getStatusText(item?.status),
    },
    {
      columnName: 'Monto',
      columnValue: (item: Invoice) =>
        item?.plan?.price ? `$${item?.plan?.price as string}` : 'No aplica',
    },
  ];
  useEffect(() => {
    callUserInvoices({
      variables: {
        limit: pagination.pageSize,
        offset: 0,
      },
    });
  }, [callUserInvoices, pagination.pageSize]);
  return (
    <CardSection
      title={
        <Typography variant="h6" fontWeight={800}>
          Transacciones
        </Typography>
      }
      subheader={
        <Typography variant="subtitle1">
          Acá encontraras información detallada del historial de pagos
        </Typography>
      }
    >
      {invoices ? (
        <TableDefault
          count={total}
          items={invoices as Invoice[]}
          columns={COLUMNS}
          page={pagination.page}
          pageSize={pagination.pageSize}
          loading={loading}
          messageLoading={'Cargando facturas...'}
          showPagination
          handleChangePage={(page) => {
            console.log({ page });
            callUserInvoices({
              variables: {
                offset: page * pagination.pageSize,
                limit: pagination.pageSize,
              },
            });
            setPagination({
              ...pagination,
              page: page + 1,
            });
          }}
        />
      ) : null}
    </CardSection>
  );
};
