import React from 'react';
import { useNavigate } from 'react-router-dom';
import { openDialogAlert } from '../alert/alert-actions';
import { Plans } from '../plan/Plans';

type ErrorViewProps = {
  text?: string;
};
export const ErrorViewNoSubscription: React.FC<ErrorViewProps> = ({
  text = 'No tienes permisos para acceder a esta página',
}) => {
  const navigate = useNavigate();
  openDialogAlert(text, () => navigate('/'));
  return <Plans />;
};
